<template>
    <v-dialog max-width="500" v-model="show">
      <v-card>
        <v-card-text>Would you like to close the checkout page?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text="Close" @click="$emit('close')"></v-btn>
          <v-btn text="Stay" @click="$emit('stay')"></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script lang="ts" setup>
  let show = true;
  </script>
  