<template>
    <div v-if="showTabs && property" style="
display: flex;
justify-content: space-between;
/* // align-items: center; */
padding: 5px;
isolation: isolate;
background: #FFFFFF;
box-shadow: 0px 4px 25px rgba(0, 56, 25, 0.1);
border-radius: 12px;
" class="sitebook-navigation-header">
        <div class="header-tabs">
            <div></div>
            <div class="header-tab" :class="[{ 'header-tab-active': route.fullPath.includes('/grid') }]">
                <NuxtLink :to="{ path: `/sitebook/${property.propertyGuid}/grid`, query: { date: route.query.date } }">
                    <span class="header-tab-text"> Availability calendar</span>
                </NuxtLink>
            </div>
            <div class="header-tab" :class="[{ 'header-tab-active': route.fullPath.includes('/company') }]">
                <NuxtLink :to="{ path: `/sitebook/${property.propertyGuid}/company`,  query: { date: route.query.date } }">
                    <span class="header-tab-text">Items search</span>
                </NuxtLink>
            </div>
            <!-- <div class="header-tab" :class="[{ 'header-tab-active': route.fullPath.includes('/types') }]">
                <NuxtLink :to="`/sitebook/${route.params.propertyguid}/company`">
                    <span class="header-tab-text">Rental types</span>
                </NuxtLink>
            </div> -->
            <div></div>
        </div>
        <div class="header-cart">
            <ClientOnly>
                <NuxtLink :to="`/sitebook/${property.propertyGuid}/cart`">
                    <svg class="cart-button" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.5" y="2.5" width="43" height="43" rx="21.5" stroke="#3C464D" />
                        <rect v-if="cartStore.length > 0" x="32" width="14" height="14" rx="7" fill="#3C9273" />

                        <text v-if="cartStore.length > 0" x="36" y="11" font-family="Poppins" font-weight="700"
                            font-size="11" fill="#FCFFFE">{{
                                cartStore.length }}</text>
                        <path
                            d="M26.5446 25C27.2943 25 27.9541 24.59 28.2939 23.97L31.8726 17.48C32.2425 16.82 31.7627 16 31.0029 16H16.2084L15.2688 14H12V16H13.9993L17.5979 23.59L16.2484 26.03C15.5187 27.37 16.4783 29 17.9978 29H29.9933V27H17.9978L19.0974 25H26.5446ZM17.1581 18H29.3036L26.5446 23H19.5272L17.1581 18ZM17.9978 30C16.8982 30 16.0085 30.9 16.0085 32C16.0085 33.1 16.8982 34 17.9978 34C19.0974 34 19.997 33.1 19.997 32C19.997 30.9 19.0974 30 17.9978 30ZM27.9941 30C26.8945 30 26.0048 30.9 26.0048 32C26.0048 33.1 26.8945 34 27.9941 34C29.0937 34 29.9933 33.1 29.9933 32C29.9933 30.9 29.0937 30 27.9941 30Z"
                            fill="#3C464D" />
                    </svg>
                </NuxtLink>
            </ClientOnly>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useRoute } from 'vue-router';
let cartStore = useCartStore().cart;
const props = defineProps({
    selectedToggle: {
        type: String,
        required: false, default: "grid"
    }, //can be grid, types, or dates
});
let selected = ref("grid");
let route = useRoute();
let property = usePropertyStore().currentProperty;

let showTabs = computed(() => {
    if (
        route.name?.toString().toLowerCase() == "cart" ||
        route.name?.toString().toLowerCase() == "confirmation"
    ) {
        return false;
    } else {
        return true;
    }
});

</script>

<style scoped lang="scss">
/* If the screen size is 1000px wide or more, set the font-size of <div>*/
@media screen and (min-width: 1000px) {
    .header-tab-text {
        font-size: 20px;
    }

    .cart-button {
        width: 40px;
        height: 45px;
    }
}

/// mid size screen
@media screen and (min-width: 601px) and (max-width: 999px) {
    .header-tab-text {
        font-size: 2vw;
    }

    .cart-button {
        width: 35px;
        height: 40px;
    }
}

/* small screen; If the screen size is 600px wide or less, set the font-size of <div> */
@media screen and (max-width: 600px) {
    .header-tab-text {
        font-size: 12px;
    }

    .cart-button {
        width: 25px;
        height: 30px;
    }
}

.header-tabs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    justify-items: center;
    flex-wrap: nowrap;
    align-items: center;
    width: 90%;
}


.header-tab {
    margin: 5px;

    a {
        line-height: 30px;
        letter-spacing: 0.02em;
        color: #3C464D;
        text-decoration: none;

        &:hover {
            font-weight: 600;
        }
    }
}

.header-tab-active {
    margin: 5px;
    line-height: 30px;
    letter-spacing: 0.02em;

    a {

        color: #009472;
        text-decoration: underline;

        &:hover {
            font-weight: 500;
        }
    }
}
</style>