<template>
  <v-app style="background-color: transparent !important;">
    <p v-html="currentProperty?.checkoutSettings?.analyticsScriptBody"></p>  
    <div class="modal-mask">
      <div class="book-wrap" id="book-wrap">
        <div class="modal-dialog">
          <div class="modal-content">
            <SitebookCloseModal @close="closeParentiFrame()" @stay="showCloseModal = false" v-if="showCloseModal">
            </SitebookCloseModal>
            <div class="modal-header">
              <div>{{ currentProperty?.name }}</div>
              <v-icon @click="showCloseModal = true" icon="mdi-close" color="black"></v-icon>
            </div>
            <div class="modal-body">
              <v-main class="main">
                <v-col v-if="route.meta.hideMenus == true"></v-col>
                <v-col v-else class="text-right">
                  <SitebookToggleNavigation></SitebookToggleNavigation>
                </v-col>
                <slot></slot>
              </v-main>
            </div>
            <SitebookFooter></SitebookFooter>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script lang="ts" setup>
const { currentProperty } = storeToRefs(usePropertyStore());

useHead({
  script: [{ innerHTML: currentProperty.value?.checkoutSettings?.analyticsScriptHead }],
});

let route = useRoute();
let showCloseModal = ref(false);

function closeParentiFrame() {
  showCloseModal.value = false;
  window.parent.postMessage('{ "type": "treqit.close" }', "*");
  window.postMessage('{ "type": "treqit.close" }', "*");
}

</script>

<style scoped lang="scss">
.book-logo {
  background-repeat: no-repeat;
  margin: 0;
  padding: 0;
  width: 150px;
}


.modal-footer {
  display: block;
}

@media only screen and (max-width: 680px) {
  .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 0px;
  }
}

@media only screen and (min-width: 680px) {
  .modal-dialog {
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 30px 15px 30px 15px;
    max-width: 980px;
  }

  .container {
    // override
    padding-right: 5px;
    padding-left: 5px;
  }
}

.book-wrap {
  top: 0;
  left: 0;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  height: 100%;
  outline: 0;
  z-index: 1072;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 60%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 7px;
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  box-shadow: 0 5px 15px rgb(0 0 0 / 17%);
  border: 1px solid #afb8c0;
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.modal-default-button {
  float: right;
}

/*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

@media (min-width: 992px) {
  .modal-dialog {
    max-width: 1100px !important;
  }
}


.modal-open {
  overflow: hidden
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0
}

.modal-dialog {
  position: relative;
  width: auto;

  margin-left: auto;
  margin-right: auto;
  pointer-events: none
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translateY(-50px)
}

@media(prefers-reduced-motion:reduce) {
  .modal.fade .modal-dialog {
    transition: none
  }
}

.modal.show .modal-dialog {
  transform: none
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02)
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem)
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden
}

.modal-dialog-scrollable .modal-footer,
.modal-dialog-scrollable .modal-header {
  flex-shrink: 0
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem)
}

.modal-dialog-centered:before {
  display: block;
  height: calc(100vh - 1rem);
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  content: ""
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none
}

.modal-dialog-centered.modal-dialog-scrollable:before {
  content: none
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: .3rem;
  outline: 0;
  background: url('@/assets/img/main-banner-trees-1.png') white 60% 20% no-repeat;


  &::before {
    overflow: hidden;
    display: block;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url('@/assets/img/main-banner-trees-1.png') left bottom no-repeat;
    background-blend-mode: darken;
    background-size: 1100px, auto;
    background-position: calc(100%) calc(100% + 62px);
    pointer-events: none;
  }


  &::after {
    overflow: hidden;
    display: block;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url('@/assets/img/action-banner-bg.png') right bottom no-repeat;
    background-blend-mode: darken;
    background-size: 500px, auto;
    background-position: right -150px bottom -230px;
    pointer-events: none;
    opacity: 0.1;
  }
}

///not used
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000
}

.modal-backdrop.fade {
  opacity: 0
}

.modal-backdrop.show {
  opacity: .1
}

///not usded ^^^^^
.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px)
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: .5rem
}


.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll
}

@media(min-width: 576px) {
  // .modal-dialog {
  //   max-width: 500px;
  //   margin: 1.75rem auto
  // }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem)
  }

  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem)
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem)
  }

  .modal-dialog-centered:before {
    height: calc(100vh - 3.5rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content
  }

  .modal-content {
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .5);
  }

  .modal-sm {
    max-width: 300px
  }
}

@media(min-width: 992px) {

  .modal-lg,
  .modal-xl {
    max-width: 800px
  }
}

@media(min-width: 1200px) {
  .modal-xl {
    max-width: 1140px
  }
}
</style>